<script setup>
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import DynamicComponent from '@/commonComponents/dynamicComponent.vue';
import Table from '@/commonComponents/dynamic/table.vue';
import {ref, watch} from "vue";
import {shaKey} from "@/js/helper";
import {useRoute} from "vue-router/composables";

const route = useRoute();
const _url = ref('');

const pUrl = "?f=testing&f2=configureAndRunTests";

watch(() => route.query, () => {
  const id = route.query['history-for'];
  if (!id) {
    _url.value = pUrl;
    return;
  }
  _url.value = `${pUrl}&n_id=${id}&n_id_key=${shaKey(id)}&function=showsHistoryContent`;
}, {immediate: true});

</script>

<template>
  <PhpServiceView :url="_url" #default="{ result }">
    <Table :result="result"/>
    <DynamicComponent
        v-for="type in result.content"
        :key="type"
        :type="type"
        :result="result"
        :additional="{}">
      {{ result }}
    </DynamicComponent>
  </PhpServiceView>
</template>

<style scoped>

</style>